import { atoms } from "@/utils/helpers/atoms";
import { ls } from "@/utils/helpers/localstorage";
import { ss } from "@/utils/helpers/sessionStorage";
import { useAtomValue } from "jotai";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import loadingAnimation from "../../../assets/lottie/LoadingLine.json";
import { defaultPage, paths } from "../../../routerPaths";
import { NotificationChannelManager } from "../../../utils/helpers/notificationChannel";
import { Body, text, title } from "../index.style";
const Step4 = ({
  fromOdience,
  onSuccess,
}: {
  fromOdience?: boolean;
  onSuccess?: VoidFunction;
}) => {
  const provisionSuccess = useAtomValue(atoms.provisioning.result);
  const navigate = useNavigate();
  const eventGroupId = ss.getLastGroupId();
  const eventId = ss.getLastEventId();
  const uri = localStorage.getItem("uriBeforeRelogin");
  if (uri) {
    localStorage.removeItem("uriBeforeRelogin");
    const accessToken = ls.getAccessToken(),
      user = ls.getUser();
    console.log(accessToken + "=" + user);
    if (accessToken && user) {
      const notificationChannelManager = new NotificationChannelManager();
      console.log("Subscribing to WebGW");
      notificationChannelManager.subscribe(accessToken, user, "");
    }
  }
  const page = uri ?? defaultPage;

  const redirectToMain = () => {
    const navigatePath =
      fromOdience && eventGroupId && eventId
        ? generatePath(paths.details, {
            groupId: eventGroupId,
            eventId: eventId,
          })
        : fromOdience && eventId
          ? generatePath(paths.stream, {
              eventId: eventId,
            })
          : fromOdience
            ? paths.odience
            : page;

    void navigate(navigatePath, { replace: true });
  };

  useEffect(() => {
    if (provisionSuccess) {
      if (onSuccess) {
        onSuccess();
      } else {
        redirectToMain();
      }
    }
  }, [provisionSuccess]);

  return (
    <Body>
      <div css={{ padding: "0 25px" }}>
        <div css={title}>
          <h2
            css={[
              text,
              { fontSize: "1.75em", fontWeight: "400", marginBottom: "2em" },
            ]}
          >
            Thank You For Using {fromOdience ? "Odience" : "Verse Messages"}
          </h2>
        </div>
        <div
          css={[
            text,
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <span>Your registration is being processed.</span>{" "}
          <Lottie
            id="loadingAnimation"
            css={{
              height: "20vh",
              position: "relative",
              bottom: "95px",
              left: "10px",
              marginBottom: "-118px",
            }}
            animationData={loadingAnimation}
            loop={true}
          />
        </div>
      </div>
    </Body>
  );
};

export default Step4;
