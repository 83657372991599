import { deleteDbs } from "@/utils/database";
import { atoms, resetAtoms } from "@/utils/helpers/atoms";
import { ls } from "@/utils/helpers/localstorage";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { queryClient } from "@/utils/queryClient";
import { useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { paths } from "../../../routerPaths";
import { Body } from "../index.style";

type Props = {
  next: (next?: any) => void;
  provision: () => Promise<boolean>;
  otprequired: () => void;
  finish: () => void;
  onRedirectToReloginPage: () => void;
};

export const clearApplicationData = async () => {
  console.log("Clearing application data");
  await deleteDbs();
  await queryClient.cancelQueries();
  queryClient.clear();
  resetAtoms();
};

const Step0 = ({
  next,
  provision,
  otprequired,
  finish,
  onRedirectToReloginPage,
}: Props) => {
  const isKickedOutByServer = ls.getForcedSignedOut();

  const provisionPromiseRef = useRef<Promise<boolean> | undefined>(undefined);
  useEffect(() => {
    const onLoadProv = async (): Promise<void> => {
      if (isKickedOutByServer) {
        await clearApplicationData();
        onRedirectToReloginPage();
        return;
      }

      if (provisionPromiseRef.current) {
        return;
      }
      provisionPromiseRef.current = provision();
      if (await provisionPromiseRef.current) {
        const uri = localStorage.getItem("uriBeforeRelogin");
        console.log("Already provisioned, skipping provisioning steps" + uri);
        if (uri) {
          otprequired();
        } else {
          finish();
        }
      } else {
        await clearApplicationData();

        console.log(
          "Coudn't reuse old prov data, going to provisioning steps..."
        );
        next();
      }
      provisionPromiseRef.current = undefined;
    };
    onLoadProv();
  }, []);

  const provisionSuccess =
    useAtomValue(atoms.provisioning.result) && isProvisioned();

  return (
    !isKickedOutByServer && (
      <Body>
        {provisionSuccess && <Navigate replace to={paths.messages} />}
      </Body>
    )
  );
};

export default Step0;
