import { IconButton, OnClickLoadingButton } from "@/components/shared/Button";
import { ProvisionCallback } from "@/types/provisioning";
import { atoms } from "@/utils/helpers/atoms";
import CloseIcon from "@mui/icons-material/Close";
import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import expandmore from "../../../assets/provisioning/expand_more_black_24dp.svg";
import OtpInput from "../../../components/shared/OtpInput";
import { colors } from "../../../styles/global.styles";
import { ls } from "../../../utils/helpers/localstorage";
import { otpRequest } from "../../../utils/helpers/loginAndCaps";
import {
  Body,
  closeButton,
  otpInputBoxStying,
  otpInputsBox,
  registerButton,
  step3ContinueButton,
  stepBody,
  stepHeader,
  text,
  textSecondary,
} from "../index.style";

type Props = {
  otpSent: (otpSent?: any) => void;
  provision: ProvisionCallback;
  prev: (prev?: any) => void;
  navigate: () => void;
};

const Step3 = ({ otpSent, provision, prev, navigate }: Props) => {
  const [otp, setOtp] = useState("");
  const phoneNumber = useAtomValue(atoms.provisioning.user);
  const handleOutput = (value: any) => {
    setOtp(value);
  };
  const [otpRequestSent, setOtpRequestSent] = useState(false);

  const handleOtp = async () => {
    if (otp.length !== 4) {
      return;
    }

    const accessToken = ls.getAccessToken();
    if (!accessToken) {
      console.warn("No local access token");
      return;
    }
    if (phoneNumber) {
      ls.setUser(phoneNumber);
    }
    setOtpRequestSent(true);
    await otpRequest(otp, accessToken);
  };

  const provisionPromiseRef = useRef<Promise<boolean> | null>(null);
  const handleProvision = () => {
    if (provisionPromiseRef.current) return;

    provisionPromiseRef.current = provision(phoneNumber);
    provisionPromiseRef.current
      .then((result: boolean) => {
        console.log("Result for provisiong: ", result);
        if (result === false) {
          provisionPromiseRef.current = null;
          return;
        } else {
          otpSent();
        }
      })
      .finally(() => {
        provisionPromiseRef.current = null;
      });
  };

  useEffect(() => {
    handleProvision();
  }, []);

  return (
    <>
      <Body>
        <IconButton css={closeButton}>
          <CloseIcon onClick={navigate} />
        </IconButton>
        <div className="stepBody" css={stepBody}>
          <h1
            id="stepHeader"
            css={[text, stepHeader, { marginTop: "0 !important" }]}
          >
            Verify It's You
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div>
              <p css={text}>
                Enter the <span css={textSecondary}>4-digit code</span> we've
                just sent via SMS to {phoneNumber}
              </p>
            </div>

            <div
              id="otpInputsBox"
              css={[
                otpInputsBox,
                {
                  backgroundColor: colors.primaryBackgroundLighter,
                  borderRadius: "20px",
                  padding: "50px",
                },
              ]}
            >
              <OtpInput
                amount={4}
                autoFocus
                handleOutputString={handleOutput}
                inputProps={{
                  className: "2fa-box",
                  css: otpInputBoxStying,
                  placeholder: "",
                }}
                inputRegExp={/^[0-9]$/}
              />
            </div>

            <OnClickLoadingButton
              disabled={!otp || otp.length < 4}
              onClick={handleOtp}
              type="submit"
              forceLoading={otpRequestSent}
              css={[
                registerButton,
                step3ContinueButton,
                {
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  height: "55px",
                  width: "100%",
                },
              ]}
            >
              Continue
            </OnClickLoadingButton>

            <button
              type="button"
              onClick={prev}
              css={{
                color: colors.primaryTextColor,
                marginTop: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={expandmore}
                css={{
                  filter:
                    "invert(100%) sepia(13%) saturate(359%) hue-rotate(300deg) brightness(115%) contrast(100%)",
                  marginLeft: "-6px",
                  position: "relative",
                  transform: "rotate(90deg)",
                }}
              />
              Back to login
            </button>
          </form>
        </div>
      </Body>
    </>
  );
};

export default Step3;
