import { getDefaultStore } from "jotai";
import Cookies from "js-cookie";
import { parsePhoneNumber } from "libphonenumber-js";
import { atoms } from "./atoms";
import { ls } from "./localstorage";

const defaultStore = getDefaultStore();
const COOKIE_DEVICE_NAME = "deviceName";
const COOKIE_USER = "user";
const COOKIE_EMAIL = "email";
const COOKIE_REMEMBER_ME = "rememberMe";

// Default values for user data
export const getLoginInformationFromCookies = (ignoreRememberMe = false) => {
  let localCountry = ls.getUserLocationCountry();
  let areaCode = localCountry.country_code;
  let phoneNumber = "";
  let email = "";
  let deviceName = "";

  const deviceNameCookie = Cookies.get(COOKIE_DEVICE_NAME);
  const userCookie = Cookies.get(COOKIE_USER);
  const emailCookie = Cookies.get(COOKIE_EMAIL);
  const checkedCookie = !!Cookies.get(COOKIE_REMEMBER_ME);

  if (ignoreRememberMe || checkedCookie) {
    if (userCookie) {
      const parsedNumber = parsePhoneNumber(userCookie);
      areaCode = "+" + parsedNumber.countryCallingCode;
      phoneNumber = parsedNumber.nationalNumber;
      if (parsedNumber.country) {
        localCountry = {
          country_code: areaCode,
          country_iso_code: parsedNumber.country,
        };
      }
    }
    if (emailCookie) email = emailCookie;
    if (deviceNameCookie) deviceName = deviceNameCookie;
  }

  return {
    areaCode,
    phoneNumber,
    deviceName,
    email,
    localCountry,
    checked: checkedCookie,
  };
};

// Store user cookies
export const storeLoginCookies = (
  phoneNumber: string,
  deviceName: string,
  rememberMe: boolean
) => {
  defaultStore.set(atoms.provisioning.user, phoneNumber);
  ls.setDeviceName(deviceName);

  const options: Cookies.CookieAttributes = {
    secure: true,
    sameSite: "None",
  };

  Cookies.set(COOKIE_DEVICE_NAME, deviceName, options);
  Cookies.set(COOKIE_USER, phoneNumber, options);

  if (rememberMe) {
    Cookies.set(COOKIE_REMEMBER_ME, "true", options);
  } else {
    Cookies.remove(COOKIE_REMEMBER_ME);
  }
};

// Remove user cookies
export const removeLoginCookies = () => {
  Cookies.remove(COOKIE_DEVICE_NAME);
  Cookies.remove(COOKIE_USER);
  Cookies.remove(COOKIE_EMAIL);
  Cookies.remove(COOKIE_REMEMBER_ME);
};

export const getCookieEmail = () => Cookies.get(COOKIE_EMAIL);
export const getCookieRememberMe = () => Cookies.get(COOKIE_REMEMBER_ME);
