import { OnClickLoadingButton } from "@/components/shared/Button";
import { colors } from "@/styles/global.styles";
import { useEffect, useRef } from "react";
import expandmore from "../../../assets/provisioning/expand_more_black_24dp.svg";
import OptionalProvisionLogo from "../OptionalProvisionLogo";
import {
  Body,
  registerButton,
  step1Body,
  stepBody,
  stepHeader,
  text,
  textBody,
  textDark,
} from "../index.style";

type Props = {
  prev: (next: any) => void;
  next: (next?: any) => Promise<void>;
  fromOdience?: boolean;
};

const StepTransferSession = ({ fromOdience, prev, next }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  // The form on this page does not have any input, focus the submit button to allow Enter key for submit
  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  return (
    <Body>
      <div
        id="step1Body"
        className="stepBody"
        css={[
          textBody,
          step1Body,
          stepBody,
          {
            maxWidth: "44em",
            padding: "3em 0",
          },
        ]}
      >
        <OptionalProvisionLogo fromOdience={fromOdience} />
        <h1 id="stepHeader" css={[text, stepHeader]}>
          {fromOdience ? "Odience" : "VERSE"} is currently being used in another
          browser
        </h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <p css={[text, textDark]}>
            To use {fromOdience ? "Odience" : "Verse"} in this window, please
            press continue, this will log out the other active session.
          </p>

          <div
            className="actionContainer"
            css={{ display: "flex", flexDirection: "column" }}
          >
            <OnClickLoadingButton
              ref={buttonRef}
              onClick={next}
              type="submit"
              css={[
                registerButton,
                {
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  height: "55px",
                  width: "100%",
                },
              ]}
            >
              Continue
            </OnClickLoadingButton>
            <button
              type="button"
              onClick={prev}
              css={{
                color: colors.primaryTextColor,
                marginTop: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={expandmore}
                css={{
                  filter:
                    "invert(100%) sepia(13%) saturate(359%) hue-rotate(300deg) brightness(115%) contrast(100%)",
                  marginLeft: "-6px",
                  position: "relative",
                  transform: "rotate(90deg)",
                }}
              />
              Back to login
            </button>
          </div>
        </form>
      </div>
    </Body>
  );
};

export default StepTransferSession;
