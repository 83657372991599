import { IconButton } from "@/components/shared/Button";
import { PolicyLinks } from "@/components/shared/PolicyScreen";
import useProvisioningInfos from "@/utils/hooks/useProvisioningInfos";
import CloseIcon from "@mui/icons-material/Close";
import OptionalProvisionLogo from "../OptionalProvisionLogo";
import {
  Body,
  checkbox,
  checkedCSS,
  closeButton,
  registerButton,
  step1Body,
  stepBody,
  stepHeader,
  termsCSS,
  text,
  textBody,
  textDark,
} from "../index.style";

type Props = {
  fromOdience?: boolean;
  next: (next: any) => void;
  onCloseProvisioning: () => void;
};

const Step1 = ({ fromOdience, next, onCloseProvisioning }: Props) => {
  const { close, checked, setChecked } = useProvisioningInfos({
    next,
    onCloseProvisioning,
  });

  return (
    <Body>
      <IconButton css={closeButton}>
        <CloseIcon onClick={close} />
      </IconButton>

      <div
        id="step1Body"
        className="stepBody"
        css={[
          textBody,
          step1Body,
          stepBody,
          {
            maxWidth: "44em",
            padding: "3em 0",
          },
        ]}
      >
        <OptionalProvisionLogo fromOdience={fromOdience} />
        <h1 id="stepHeader" css={[text, stepHeader]}>
          Enjoy a Rich Communication Experience
        </h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <p css={[text, textDark]}>
            <span className="miniHeader">Terms of Service. </span>
            {fromOdience ? "ODIENCE" : "VERSE"} by Summit-Tech ("Summit"). You
            agree to our Terms of Service ("Terms") by installing or using our
            apps, services, or website (together, "Services").
          </p>
          <p css={[text, textDark, { marginTop: "1.5em" }]}>
            <span className="miniHeader">Minimum Age. </span>You must be at
            least 13 years old to use our Services.
          </p>
          <p css={[text, textDark, { marginTop: "1.5em" }]}>
            <span className="miniHeader">Account Registration. </span>To create
            an account you must sign-up for our Services using your mobile
            number. You agree to receive text messages and mobile calls (from us
            or our third-party providers) with PIN codes to register for our
            Services.
          </p>

          <div className="policyLinks">
            <PolicyLinks />
          </div>
          <div
            className="actionContainer"
            css={{ display: "flex", flexDirection: "column" }}
          >
            <div css={[checkbox, { justifyContent: "left" }]}>
              <input
                id="terms_checkbox"
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                css={checkedCSS}
              />
              <label css={termsCSS} htmlFor="terms_checkbox">
                <p>I have read the above and agree to the Terms of Service</p>
              </label>
            </div>
            <button
              type="submit"
              disabled={!checked}
              onClick={next}
              css={[
                registerButton,
                {
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  height: "55px",
                  width: "100%",
                },
              ]}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </Body>
  );
};

export default Step1;
